import React, { useEffect, useState, useCallback } from "react";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { ItemNav } from "../../form/ItemNav";
import { IKyuExam } from "../../../api/models";
import { FD, Item } from "../../form";
import { faAdd, faUser } from "@fortawesome/free-solid-svg-icons";
import { KyuService } from "../../../api";
import classNames from "classnames";
import { IPageLinkProps } from "../../page.props";
import styles from './kyu.module.scss';
import { useParamId, useParamRole } from "../../useParam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "../../foundation/Loading";
import { useNavigate } from "react-router-dom";

interface KyuProps extends IPageLinkProps {
    refresh: number;
    triggerRefresh: () => void;
}

const Kyu: React.FC<KyuProps> = ({ user, profile, gotoUrl, refresh, triggerRefresh }) => {
    const [loading, setLoading] = useState(true);
    const [exams, setExams] = useState<IKyuExam[]>([]);
    const id = useParamId();
    const role = useParamRole(user);
    const navigate = useNavigate();

    const refreshExams = useCallback(() => {
        console.log('Kyu: refreshExams called');
        setLoading(true);
        if (role != null) {
            console.log('Kyu: Fetching exams for org:', role.orgId);
            KyuService.exams(role?.orgId).then((exam) => {
                console.log('Kyu: Fetched exams:', exam);
                setExams(exam);
            }).catch(error => {
                console.error('Kyu: Error fetching exams:', error);
            }).finally(() => {
                setLoading(false);
                console.log('Kyu: Finished refreshing exams');
            });
        } else {
            console.log('Kyu: No role available, skipping exam fetch');
            setLoading(false);
        }
    }, [role]);

    useEffect(() => {
        console.log('Kyu: useEffect triggered. Refresh:', refresh);
        console.log('Kyu: Current exams:', exams);
        refreshExams();
    }, [refreshExams, refresh]);



    // useEffect(() => {
    //     console.log('gotourl_useEffect', gotoUrl);
    //     console.log('refresh', refresh);
    //     if (gotoUrl.endsWith('t=delete')) {
    //         console.log('refreshing:', gotoUrl);
    //         refreshExams();
    //         navigate(`${gotoUrl}/0?t=new`, { replace: false });
    //     }else{
    //         refreshExams();
    //         console.log('not refreshing:', gotoUrl);
    //     }
    // }, [refresh]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <ItemNavGroup label={role?.orgName}>
                    {role?.orgId > 10 ? (
                        <ItemNav
                            label="Neue Prüfung hinzufügen"
                            className={styles.add}
                            icon={faAdd}
                            href={`${gotoUrl}/0?t=new`}
                        />
                    ) : (
                        <></>
                    )}
                    {exams?.length > 0 ? (
                        exams.map((e) => (
                            (e.orgId === role?.orgId || e.status != 'ERSTELLT'  ) ? (
                                <ItemNav
                                    key={e.id}
                                    href={`${gotoUrl}/${e.id}?t=candidates`}
                                    selected={id === e.id}
                                    className={styles.item}
                                >
                                    <div className={classNames(styles.entry)}>
                                        <div className={styles.date}>{FD(e.date)}</div>
                                        <div className={styles.kyu_id}>{e.id}</div>
                                        {e.status && e.status !== 'ERSTELLT' && (
                                            <div className={styles.teilnehmer}>
                                                <FontAwesomeIcon icon={faUser} /> {e.items}
                                            </div>
                                        )}
                                    </div>
                                    {e.orgId !== role.orgId || e.orgId <= 10 ? (
                                        <div>
                                            <div className={styles.org}>{e.org}</div>
                                            <div className={styles.vorsitz}>Vorsitz: {e.examChairMain}</div>
                                            <div className={styles.editor}>Editor: {e.editor}</div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className={styles.vorsitz}>Vorsitz: {e.examChairMain}</div>
                                            <div className={styles.editor}>Editor: {e.editor}</div>
                                        </div>
                                    )}
                                    <div className={classNames(styles.status, styles[e.status?.toLowerCase() || 'freigegeben_alt'])}>
                                        {e.status ? (e.status === 'ABGESCHLOSSEN' ? ' URKUNDEN' : e.status) : 'FREIGEGEBEN'}
                                    </div>
                                </ItemNav>
                            ) : (
                                <></>
                            )
                        ))
                    ) : (
                        <Item type="list" label="Keine Termine verfügbar" />
                    )}
                </ItemNavGroup>
            )}
        </>
    );};

export default Kyu;