import React, { useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { KyuDetailEdit } from "./kyu_detail_edit";
import { KyuDetailNew } from "./kyu_detail_new";
import { KyuDetailCandidates } from "./kyu_detail_candidates";
import { KyuDetailApprove } from "./kyu_detail_approve";
import Kyu from "./kyu";
import styles from "../licences/licence.module.scss";
import { IPageLinkProps } from "../../page.props";

interface ExtendedPageLinkProps extends IPageLinkProps {
    refresh?: number;
    setRefresh?: React.Dispatch<React.SetStateAction<number>>;
    triggerRefresh: () => void;
}

export const KyuDetail: React.FC<ExtendedPageLinkProps> = (props) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');
    const [refreshCounter, setRefreshCounter] = useState(0);

    const triggerRefresh = useCallback(() => {
        console.log('triggerRefresh called');
        setRefreshCounter(prev => prev + 1);
    }, []);

    const childProps = {
        ...props,
        refresh: refreshCounter,
        setRefresh: setRefreshCounter,
        triggerRefresh,
    };

    return (
        <>
            {/* Render the appropriate component based on the 'page' parameter */}
            {page === 'edit' && <KyuDetailEdit {...childProps} />}
            {(page === 'new' || page === 'delete') && <KyuDetailNew {...childProps} />}
            {page === 'candidates' && <KyuDetailCandidates {...childProps} />}
            {page === 'approve' && <KyuDetailApprove {...childProps} />}
            {!page && (
                <div className={styles.headerempty}>
                    <p>KYU-Prüfung</p>
                    <p>Bitte wähle links</p>
                    <p><strong>+ Neue Prüfung hinzufügen</strong></p>
                </div>
            )}
        </>
    );
};

export default KyuDetail;