import React, { useCallback, useEffect, useState } from 'react';
import { IPageLinkProps } from "../../page.props";
import { useParams } from "react-router";
import { EKyuExamStatus, IKyuChairMember, IKyuExam, IOrgFunction } from "../../../api/models";
import { useNavigate } from "react-router-dom";
import { KyuService } from "../../../api";
import { BackButton, Button, DateInput, DS, Form, Input, Item, PDS } from "../../form";
import { toaster } from "../../../core/toaster";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { addMonths } from "date-fns";
import SelectKyuBordMembers from "../../tournaments/SelectKyuChairMembers";
import styles from "./kyu_detail_edit.module.scss";

interface ExtendedPageLinkProps extends IPageLinkProps {
    triggerRefresh: () => void;
    //refreshCandidatesFromParent: (force: boolean, fullRefresh: boolean) => Promise<void>;
}

export const KyuDetailEdit: React.FC<ExtendedPageLinkProps> = ({
       user,
       profile,
       gotoUrl,
       refresh,
       setRefresh,
       triggerRefresh,

}) => {    const params = useParams();
    const [date, setDate] = useState<Date | null>(new Date(Date.now()));
    const [name, setName] = useState<string | null>(null);
    const [chairMemberMain, setChairMemberMain] = useState<number | null>(null);
    const [chairMember1, setChairMember1] = useState<number | null>(null);
    const [chairMember2, setChairMember2] = useState<number | null>(null);
    const [exam, setExam] = useState<IKyuExam | null>(null);
    const [orgChairMembers, setOrgChairMembers] = useState<IKyuChairMember[]>([]);
    const navigate = useNavigate();

    const id = Number(params['id'] || 0);
    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    // console.log("ChairMembers", chairMemberMain, chairMember1, chairMember2)

    const refreshMain = useCallback(async () => {
        if (id > 0 && role?.orgId > 0) {
            try {
                const res = await KyuService.get(role.orgId, id);
                setDate(PDS(res.date));
                setName(res.name);
                setChairMemberMain(res.examChairMainId);
                setChairMember1(res.examChair1Id);
                setChairMember2(res.examChair2Id);
                setExam(res);

                // Refresh the list of chair members
                if (res.date) {
                    const chairMembers = await KyuService.listChairMembers(role.orgId, {
                        search: '',
                        date: DS(new Date(res.date))
                    });
                    setOrgChairMembers(chairMembers);
                }
            } catch (error) {
                console.error("Error in refreshMain:", error);
            }
        }
    }, [id, role?.orgId]);

    const validate = () => {
        return date != null && exam?.status === EKyuExamStatus.created;
    };

    const validateItems = () => {
        return (exam?.items ?? 0) > 0;
    };

    const update = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        console.log("Update function called");
        if (validate()) {
            console.log("Validation passed");
            try {
                await toaster(async () => {
                    console.log("Toaster function called");
                    const updatedExamId = await KyuService.update(role?.orgId, {
                        id,
                        date: DS(date as Date),
                        name: name as string,
                        examChairMember: {
                            vorsitzer: chairMemberMain as number,
                            beisitzer1: chairMember1 as number,
                            beisitzer2: chairMember2 as number,
                        }
                    });
                    console.log('updatedExamId-1:', updatedExamId);
                    if (updatedExamId) {
                        console.log('updatedExamId-2:', updatedExamId);
                        // Fetch the updated exam data
                        const updatedExam = await KyuService.get(role?.orgId, updatedExamId);
                        if (updatedExam) {
                            setExam(updatedExam);
                            console.log('Updated exam:', updatedExam);
                        }

                        triggerRefresh(); // Call this to update Kyu component
                        // Trigger a refresh of the exam list
                        setRefresh && setRefresh((prevRefresh) => prevRefresh + 1);
                        triggerRefresh(); // This will update the Kyu component

                        // Refresh the current exam details
                        await refreshMain();

                        if (id > 0) {
                            console.log("Navigating to: id>0");
                            navigate(`${gotoUrl}/${updatedExamId}?t=candidates`);

                            // Set a slight delay before reloading to ensure navigation completes
                            setTimeout(() => {
                                window.location.reload();
                            }, 100);
                        } else {
                            console.log("Navigating to: id==0");
                            // If you want to reload even when id is 0, uncomment the following:
                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 100);
                        }
                    }else {
                        console.log('updatedExamId-else:', updatedExamId);
                    }
                }, { success: 'Kyu Prüfung erfolgreich gespeichert', failure: 'Fehler beim Speichern der Prüfung!' });

                // If we reach here, it means the toaster function completed without throwing an error
                return true;
            } catch (error) {
                console.error("Error in update function:", error);
                return false;
            }
        } else {
            console.log("Validation failed");
            return false;
        }
    };

    useEffect(() => {
        if (date && role?.orgId) {
            KyuService.listChairMembers(role?.orgId, {
                search: '',
                date: DS(date as Date)
            }).then(setOrgChairMembers);
        }
    }, [date, role?.orgId]);

    useEffect(() => {
        refreshMain();
    }, [refreshMain]);

    return (
        <>
            <BackButton label={'Zurück'}/>

            <ItemNavGroup label={`Prüfung ${exam?.id}`}>

            <Form>
                    <Item type="full" label="Datum" size={6}>
                        <DateInput
                            value={date}
                            onChange={setDate}
                            minDate={new Date()}
                            maxDate={addMonths(new Date(), 3)}
                            disabled={exam?.status == EKyuExamStatus.created}
                        />
                    </Item>
                    <Item type="full" label="Ort" size={6}>
                        <Input
                            onChange={(v) => setName(v)}
                            value={name || ''}
                            //disabled={!validate()}
                            disabled={false}
                        />
                    </Item>
                    <Item type="full" label="Vorsitz">
                        <SelectKyuBordMembers
                            orgId={role?.orgId}
                            id={chairMemberMain}
                            setId={setChairMemberMain}
                            orgMembers={orgChairMembers}
                            date={date}
                            //disabled={!validate()}
                            exclude={[chairMember1 || 0, chairMember2 || 0]}

                        />
                    </Item>
                    <Item type="full" label="Beisitz 1">
                        <SelectKyuBordMembers
                            orgId={role?.orgId}
                            id={chairMember1}
                            setId={setChairMember1}
                            orgMembers={orgChairMembers}
                            date={date}
                            disabled={!validate() || chairMemberMain == null}
                            exclude={[chairMemberMain || 0, chairMember2 || 0]}
                        />
                    </Item>
                    <Item type="full" label="Beisitz 2">
                        <SelectKyuBordMembers
                            orgId={role?.orgId}
                            id={chairMember2}
                            setId={setChairMember2}
                            orgMembers={orgChairMembers}
                            date={date}
                            disabled={!validate() || chairMember1 == null}
                            exclude={[chairMemberMain || 0, chairMember1 || 0]}
                        />
                    </Item>
                </Form>
            </ItemNavGroup>
            <br/>
            <br/>
            <Button width={'full'} label="SPEICHERN" onClick={update} disabled={!validate()}/>
            {/*<Button*/}
            {/*    className={styles.executeButton}*/}
            {/*    width={'full'}*/}
            {/*    label="submit-test: Grade & Urkunden erstellen, JudoCards bestellen"*/}
            {/*    onClick={execute}*/}
            {/*    disabled={!validate() || !validateIfItems()}*/}
            {/*/>*/}
        </>
    );
};