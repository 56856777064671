import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {IPageLinkProps} from "../../page.props";
import {EKyuExamStatus, IKyuCandidate, IKyuExam} from "../../../api/models";
import {CacheService, Constants, IMAGE_URL, KyuService} from "../../../api";
import {toaster, toastError} from "../../../core/toaster";
import {ApiError} from "../../../api/http";
import {Loading} from "../../foundation/Loading";
import {ItemNav} from "../../form/ItemNav";
import {faCertificate, faCircleCheck, faEdit, faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import PullToRefresh from "react-simple-pull-to-refresh";
import {BackButton, Button, DateInput, DS, FD, Form, Input, Item, Segment} from "../../form";
import classNames from "classnames";
import styles from "./kyu_detail_candidates.module.scss";
import {useParamId, useParamRole} from "../../useParam";
import {AuthenticatedLink} from "../../foundation/authenticated_link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AvatarImage from "../../foundation/AvatarImage";
import {addMonths} from "date-fns";
import SelectKyuBordMembers from "../../tournaments/SelectKyuChairMembers";
import {useNavigate, useLocation} from "react-router-dom";

const HOST =  Constants.JAMA_SERVER_API_V2

interface KyuDetailCandidatesProps extends IPageLinkProps {
    refresh?: number;
    setRefresh?: React.Dispatch<React.SetStateAction<number>>;
    triggerRefresh: () => void;
}

export const KyuDetailCandidates: React.FC<KyuDetailCandidatesProps> = ({user, profile, gotoUrl, refresh, setRefresh, triggerRefresh}) => {
    console.log('KyuDetailCandidates rendered');
    // const params = useParams();
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [candidates, setCandidates] = useState<IKyuCandidate[]>([]);
    const [exam, setExam] = useState<IKyuExam|null>(null);
    const [search, setSearch] = useState<string>('');
    const [filterState, setFilterState] = useState<number>(3); //defailt Filterauswahl
    const location = useLocation();
    const examId = useParamId();
    const role = useParamRole(user);
    const [date, setDate] = useState<Date | null>(new Date(Date.now()));
    const [toggleLoading, setToggleLoading] = useState<number | null>(null);

    const [selectedCount, setSelectedCount] = useState<number>(0);

    const refreshCandidates = async (force: boolean, fullRefresh: boolean = false) => {
        if (role != null) {
            console.log('Starting refreshCandidates, force:', force, 'fullRefresh:', fullRefresh);
            setRefreshing(true);

            if (force) await CacheService.clear();

            try {
                if (fullRefresh) {
                    console.log('Fetching candidates');
                    setCandidates([]); // Clear candidates when doing a full refresh
                    const candidates = await KyuService.candidates(role?.orgId, examId || 0);
                    console.log('Fetched candidates:', candidates);
                    setCandidates(candidates);
                } else {
                    console.log('Skipping candidate fetch, using existing data');
                }

                setRefresh && setRefresh((prev) => prev + 1);
            } catch (e) {
                console.error('Error in refreshCandidates:', e);
                toastError((e as ApiError)?.result?.error)
            } finally {
                console.log('Finishing refreshCandidates');
                setRefreshing(false);
            }
        }
    };

    const [lastToggleTime, setLastToggleTime] = useState<number | null>(null);

    const toggleCandidate = async (candidate: IKyuCandidate) => {
        console.log("toggleCandidate called for:", candidate.mgId);
        setToggleLoading(candidate.mgId);
        try {
            setCandidates(prevCandidates => {
                const newCandidates = prevCandidates.map(c =>
                    c.mgId === candidate.mgId
                        ? { ...c, assignedMgId: c.assignedMgId ? undefined : candidate.mgId }
                        : c
                );
                const newCount = newCandidates.reduce((acc, c) => c.assignedMgId ? acc + 1 : acc, 0);
                setSelectedCount(newCount);
                return newCandidates;
            });

            console.log("Candidate toggled, about to save");
            await saveCandidates();
            console.log("Save completed after toggle");

        } catch (error) {
            console.error("Error in toggleCandidate:", error);
        } finally {
            setToggleLoading(null);
        }
    };

    const filter = useMemo(() => {
        return candidates.filter(x => {
            const matchesSearch =
                x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${x.mgId}`.includes(search);

            let passesFilter = true;
            if (filterState === 1) {
                passesFilter = x.assignedMgId != null;
            } else if (filterState === 2) {
                passesFilter = x.assignedMgId == null && (x.outError?.length || 0) === 0;
            } else if (filterState === 3) {
                passesFilter = (x.outError?.length || 0) === 0;
            }

            //console.log(`Candidate ${x.mgId}: matchesSearch=${matchesSearch}, passesFilter=${passesFilter}`);
            return matchesSearch && passesFilter;
        });
    }, [candidates, filterState, search]);

    const validateDetele = () => {
        return exam?.status===EKyuExamStatus.created || exam?.status == undefined ? false : true;
    }

    const navigate = useNavigate();

    const handleEditClick = () => {
        navigate(`${gotoUrl}/${examId}?t=edit`);
    };

    const saveCandidates = async (): Promise<boolean> => {
        console.error("Starting saveCandidates");
        try {
            setLoading(true);
            setRefreshing(true);

            console.error("Preparing candidate updates");
            const updates = candidates.map(c => c.assignedMgId);
            const updatesFiltered = (updates.filter(item => item !== null)).filter(Boolean);

            console.error("Calling batchUpdateCandidates");
            const success = await KyuService.batchUpdateCandidates(role?.orgId, exam?.id || 0, updatesFiltered);
            console.error("batchUpdateCandidates result:", success);

            console.error("Refreshing candidates");
            await refreshCandidates(true, false);

            console.error("Updating refresh state");
            setRefresh && setRefresh((prevRefresh) => {
                const newRefresh = (prevRefresh || 0) + 1;
                console.error('New refresh value:', newRefresh);
                return newRefresh;
            });

            console.error("Calling triggerRefresh");
            triggerRefresh();

            return true;
        } catch (error) {
            console.error("Error in saveCandidates function:", error);
            return false;
        } finally {
            console.error("Resetting loading and refreshing states");
            setLoading(false);
            setRefreshing(false);
        }
    };

    const execute = async (event: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (!validateIfItems()) {
            console.log("Validation failed");
            return false;
        }
        setLoading(true);
        try {
            // First, send all candidate updates
            const updates = candidates.map(c => c.assignedMgId);
            const updatesFiltered = (updates.filter(item => item !== null)).filter(Boolean);
            const success = await KyuService.batchUpdateCandidates(role?.orgId, exam?.id || 0, updatesFiltered);
            // Then, upgrade the exam
            await KyuService.upgrade(role?.orgId, exam?.id || 0);
            // Fetch updated exam data
            const updatedExam = await KyuService.get(role?.orgId || 0, exam?.id || 0);
            if (updatedExam) {
                setExam(updatedExam);
                console.log('Updated exam after upgrade:', updatedExam);
            }
            // Refresh candidates with full fetch after exam upgrade
            await refreshCandidates(true, true);
            console.log("Calling triggerRefresh_1");
            triggerRefresh();
            console.log("Calling triggerRefresh_2");
            // Update refresh state
            setRefresh && setRefresh((prevRefresh) => {
                const newRefresh = (prevRefresh || 0) + 1;
                console.log('New refresh value:', newRefresh);
                return newRefresh;
            });
            toaster(async () => {
                return Promise.resolve();
            }, {
                success: 'Kyu Prüfung erfolgreich gespeichert',
                failure: 'Fehler beim Speichern der Prüfung!'
            });
            setTimeout(() => {
                navigate(`${gotoUrl}/${exam?.id}?t=candidates`);
                window.location.reload();
            }, 0);
            return true;
        } catch (error) {
            console.error("Error in execute function:", error);
            toastError('Fehler beim Speichern der Prüfung!');
            return false;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('KyuDetailCandidates received new refresh value:', refresh);
    }, [refresh]);

    const deleteExam = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        const gotoAfterDelete = `${gotoUrl}/0?t=delete`
        console.log("gotoUrl--candidated--: ", gotoAfterDelete );
        if (exam?.status===EKyuExamStatus.created || exam?.status==undefined) {
            toaster(async () => {
                try {
                    await KyuService.delete(role?.orgId, exam?.id||0);
                    console.log("Kyu deleted successful");
                    await refreshCandidates(true, false); // Don't fetch candidates again

                    setRefresh && setRefresh((refresh || 0) + 1);
                    navigate(`${gotoUrl}/0?t=delete`);
                } catch (error) {
                    console.error("Error in toaster function:", error);
                }
            }, { success: 'Kyu Prüfung #' + exam?.id + ' gelöscht', failure: 'Fehler beim Löschen der Prüfung #' + exam?.id + ' !' });
        } else {
            console.log("Validation failed");
        }
        return false;
    };

    const approve = (data: React.MouseEvent<HTMLButtonElement>): boolean => {
        if (validateApprove()) {
            toaster(async () => {
                await KyuService.approve(role?.orgId || 0, exam?.id || 0);
                // Fetch updated exam data
                const updatedExam = await KyuService.get(role?.orgId || 0, exam?.id || 0);
                if (updatedExam) {
                    setExam(updatedExam);
                    console.log('Updated exam after upgrade:', updatedExam);
                }

                // Refresh candidates
                await refreshCandidates(true);
                setRefresh && setRefresh((refresh ||0)+1);
                navigate(`${gotoUrl}/${exam?.id}?t=candidates`)
                return
            }, {success: 'Kyu Prüfung erfolgreich durchgeführt', failure: 'Fehler!'})
        }
        return false
    }

    const validateApprove = () => {
        return date != null && exam?.status === EKyuExamStatus.completed;
    }

    const validateIfItems = () => {
        return exam?.items && exam.items > 0 ? true : false;
    };
    //--

    useEffect(() => {
        setLoading(true);
        setCandidates([]); // Clear candidates when examId changes
        console.log('fetchedCandidates:1');
        const fetchCandidates = async () => {
            try {
                const fetchedCandidates = await KyuService.candidates(role?.orgId, examId || 0);
                setCandidates(fetchedCandidates);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCandidates();
    }, [role?.orgId, examId]);

    useEffect(() => {
        if (role?.orgId) {
            console.log('get:3');
            KyuService.get(role.orgId, examId || 0).then(fetchedExam => {
                if (fetchedExam) {
                    setExam(fetchedExam);
                    console.log("Before updating exam items");
                    console.log('Updated exam items:', fetchedExam.items);
                    console.log("After updating exam items");
                }
            });
        }
    }, [role, examId, refresh]);

    useEffect(() => {
        const count = candidates.reduce((acc, c) => c.assignedMgId ? acc + 1 : acc, 0);
        setSelectedCount(count);
    }, [candidates]);

    useEffect(() => {
        //console.log('Filtered candidates:', filter);
    }, [filter]);

    useEffect(() => {
        validateIfItems();
    }, [exam?.items]);

    useEffect(() => {
        console.log('lastToggleTime effect triggered', lastToggleTime);
        if (lastToggleTime !== null) {
            console.log("Setting up save timer");
            const timer = setTimeout(() => {
                console.log("Save timer triggered, calling saveCandidates");
                saveCandidates();
            }, 700);

            return () => {
                console.log("Clearing save timer");
                clearTimeout(timer);
            };
        }
    }, [lastToggleTime]);

    //--

    //console.log('exam.orgid/role.orgId: ', exam?.orgId, role.orgId);
    return <>
        {(loading || refreshing) && <Loading />}

        <BackButton label={'Prüfung verlassen'} navigateTo={gotoUrl}/>
        <ItemNavGroup label={`Prüfung ${exam?.id} / ${exam?.status} `} className={styles.itemNavGroup}>
            {/*<div className={styles.itemNavGroup}>*/}
                <Item type="split" label={`Datum: ${FD(exam?.date)}`} size={12}></Item>
                {exam?.name && <Item type="full" label={`Name: ${exam?.name}`} size={12}></Item>}
                <Item type="full" label={`Verein: ${exam?.org}`} size={12}></Item>
                <Item type="full" label={`Vorsitz: ${exam?.examChairMain}`} size={12}></Item>

                {exam?.examChair1 && <Item type="full" label={`1.Beisitz: ${exam?.examChair1}`} size={12}></Item> }
                {exam?.examChair2 && <Item type="full" label={`2.Beisitz: ${exam?.examChair2}`} size={12}></Item> }

                <Item type="full" label={`Teilnehmer gespeichert: ${exam?.items} | ausgewählt: ${selectedCount}`} size={12}>
                    {exam?.items != selectedCount ? <div className={styles.hint}>Es sind noch nicht alle Teilnehmer gespeichert &rarr; "Prüfung zwischenspeichern" drücken! </div> : ''}
                </Item>
                <Item type="full" label={`Zuletzt bearbeitet von: ${exam?.editor}`} size={12}>
                    {/*<div className={classNames(styles.children, styles.editor)}>{exam?.editor}</div>*/}
                </Item>
            {/*</div>*/}
        </ItemNavGroup>
        {exam?.status===EKyuExamStatus.created && exam?.orgId == role.orgId && (
            <Button
                className={styles.editButton}
                width={'full'}
                label="Datum, Vorsitz und Beisitz bearbeiten"
                onClick={handleEditClick}
            />
        )}
        {/*{ (exam?.status === EKyuExamStatus.completed && role.orgLevel < 3) ?*/}
        {/*    <ItemNav*/}
        {/*    primary={true}*/}
        {/*    label={`Prüfung Nr. ${examId}: Freigeben`}*/}
        {/*    icon={faCircleCheck}*/}
        {/*    href={`${gotoUrl}/${examId}?t=approve`}*/}
        {/*/> : '' }*/}

        {exam?.status===EKyuExamStatus.created && exam?.orgId == role.orgId && (
            <Button
                className={styles.executeButton}
                width={'full'}
                label="Prüfung abschließen, Grade eintragen, Urkunden erstellen & JudoCards bestellen"
                onClick={execute}
                disabled={!validateIfItems()}
            />
        )}
        {/*{exam?.status===EKyuExamStatus.created && exam?.orgId == role.orgId && (*/}
        {/*    <Button*/}
        {/*        className={styles.saveButton}*/}
        {/*        width={'full'}*/}
        {/*        label="Prüfung zwischenspeichern"*/}
        {/*        onClick={() => saveCandidates()}*/}
        {/*        disabled={exam?.items != selectedCount ? false : true}*/}
        {/*    />*/}
        {/*)}*/}

        {(exam?.status===EKyuExamStatus.created || exam?.status =='ABGESAGT'  ) && exam?.orgId == role.orgId && (
        <Button
            className={styles.deleteButton}
            width={'full'}
            label="Prüfung unwiderruflich löschen"
            onClick={deleteExam}
            disabled={validateDetele()}
        />)}

        {exam?.status===EKyuExamStatus.submitted && (exam?.orgId != role.orgId || role?.orgLevel < 3) && (
            <Button
                className={styles.approveButton}
                width={'full'}
                label="Freigeben"
                onClick={approve}
                disabled={!validateApprove()}
            />
        )}

        {/*<LoadingTest />*/}


        <ItemNavGroup>
            <div className={styles.candidatesContainer}>

                <PullToRefresh onRefresh={() => refreshCandidates(false, false)} onFetchMore={async () => {
                    console.log('more')
                }}>
                    <>
                        {(loading || refreshing) && (
                            <div className={styles.loadingOverlay}>
                                <Loading />
                                <div>Daten werden geladen...</div>
                            </div>
                        )}
                        <Item type="full">
                            <Input onChange={setSearch}
                                   className={styles.name} value={search} placeholder={'Suche'}/>
                        </Item>
                        <Item type="full">
                            {/*<Segment*/}
                            {/*    className={styles.filter}*/}
                            {/*    value={filterState}*/}
                            {/*    onChange={setFilterState} options={[*/}
                            {/*    ...((exam?.orgId == role.orgId && (exam?.status === EKyuExamStatus.created)) ? [{*/}
                            {/*        label: 'alle Judoka',*/}
                            {/*        value: 0*/}
                            {/*    }] : []),*/}
                            {/*    // ...((exam?.orgId == role.orgId && (exam?.status === EKyuExamStatus.created)) ? [{*/}
                            {/*    //     label: 'nur zulässige Judoka',*/}
                            {/*    //     value: 2*/}
                            {/*    // }] : []),*/}
                            {/*    ...((exam?.orgId == role.orgId && (exam?.status == 'ERSTELLT' || exam?.status == undefined)) ? [{*/}
                            {/*        label: 'mögliche',*/}
                            {/*        value: 3*/}
                            {/*    }] : []),*/}
                            {/*    ...((exam?.orgId == role.orgId && (exam?.status === EKyuExamStatus.created)) ? [{*/}
                            {/*        label: 'nur angemeldete',*/}
                            {/*        value: 1*/}
                            {/*    }] : []),*/}

                            {/*]}/>*/}
                            <div className={styles.filterHeader}>Filter</div>
                            <Segment
                                className={styles.filter}
                                value={filterState}
                                onChange={setFilterState}
                                options={[
                                    { label: 'Teilnehmerauswahl ', value: 3 },
                                    { label: 'Anmeldeübersicht', value: 1 },
                                    { label: 'Alle Judoka', value: 0 },
                                    //{ label: 'Filter: Nur zulässige', value: 2 },
                                ]}
                            />
                        </Item>
                        {exam?.status !== EKyuExamStatus.created ?
                            <AuthenticatedLink
                                className={classNames(styles.all, styles.kyuUrkunden)}
                                url={`${HOST}/admin/${role.orgId}/report/kyu/${examId}`}
                                filename={"Kyu-Urkunden.pdf"}
                            >Alle Urkunden <FontAwesomeIcon icon={faFileArrowDown}/> nach Gürtel
                                sortiert</AuthenticatedLink> :
                            <div className={styles.hint}>HINWEIS: Urkunden wurden vom Verein noch nicht erstellt!</div>}

                        {/*{console.log('filter:', filter)}*/}
                        {/*{console.log('filterState:', filterState)}*/}
                        <div className={styles.candidatesContainer}>
                            {loading && <div className={styles.loadingOverlay}><Loading/></div>}

                            {filter.length > 0 ? filter.map(c => (

                                <Item
                                    type="full"
                                    key={`candidate-${c.mgId}`}
                                    onClick={exam?.status === EKyuExamStatus.created && (filterState==1 || ((c.outError?.length || 0) === 0)) ? () => toggleCandidate(c) : undefined}
                                    //onClick={exam?.status === EKyuExamStatus.created /* ignore outErrors*/ ? () => toggleCandidate(c) : undefined}
                                >

                                    <div className={classNames(
                                        styles.candidate,
                                        c.assignedMgId != null ? styles.active : styles.inactive,
                                        c.judocard == null && styles.nojudocard,
                                        c.outError?.length && styles.regelverletzung
                                    )}>
                                        {toggleLoading === c.mgId && <Loading/>}
                                        <div className={styles.card}>
                                            <div className={styles.imageContainer}>
                                                <AvatarImage className={styles.image}
                                                             src={IMAGE_URL + c?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                                />
                                            </div>
                                            <div className={styles.rightSide}>
                                                <div className={styles.header}>
                                                    <strong>{c.surname} {c.firstname} {c.assignedMgId && ' | angemeldet '}</strong>
                                                    <div>{FD(c.birthdate)} / {c.mgId}</div>
                                                    {(exam?.status === EKyuExamStatus.completed || exam?.status === EKyuExamStatus.approved || exam?.status == undefined) && c.assignedMgId ?
                                                        <AuthenticatedLink
                                                            url={`${HOST}/admin/${role.orgId}/report/kyu/${examId}/${c.mgId}`}
                                                            filename={`KyuCertificate_${c.surname}_${c.firstname}_${exam?.date}.pdf`}
                                                            className={classNames(styles.kyuUrkunden)}
                                                        >Urkunde&nbsp;<FontAwesomeIcon icon={faFileArrowDown}/>
                                                        </AuthenticatedLink>
                                                        : ''}
                                                </div>
                                                <div className={styles.lastexam}>
                                                    <div><br/>Letzte Prüfung: {FD(c.lastKyuDate)}</div>
                                                    {c.judocard ?
                                                        <div
                                                            className={classNames(styles.licence, styles.valid)}>JUDOCARD: {c.judocard}</div>
                                                        : <div
                                                            className={classNames(styles.licence, styles.missing)}>JudoCard:
                                                            *wird bestellt*</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.info}>

                                            <div className={styles.belt}>
                                                <div className={styles.beltInfo}>
                                                    aktuell: {c.trainingBelt}
                                                    <div dangerouslySetInnerHTML={{__html: c.beltsvg}}/>
                                                </div>
                                                {c.nextName && (<div className={styles.beltInfo}>
                                                    nächster: {c.nextName}
                                                    <div dangerouslySetInnerHTML={{__html: c.nextBeltSvg || ''}}/>
                                                </div>)}
                                            </div>
                                        </div>
                                        {/*<div className={styles.select}>
                                             <Segment onChange={() => null} defaultValue={false} options={[
                                             {value: false, label: 'Frei'},
                                             {value: true, label: 'Angemeldet'},
                                           ]} />
                                        </div>*/}
                                        {((c.outError?.length || 0) > 0) && exam?.status === EKyuExamStatus.created && (
                                            <div className={styles.error}>
                                                {c?.outError?.map(x => x.message).join(' | ')}
                                            </div>)}

                                    </div>
                                </Item>
                            )) : /*exam?.items && exam?.items > 0 ? <Loading/> :*/ (<p>Keine Kandidaten verfügbar</p>)}
                        </div>
                    </>
                </PullToRefresh>
            </div>
        </ItemNavGroup>
    </>
};